/* eslint-disable import/no-cycle */
/* eslint-disable no-unreachable */
import { initialAbility } from '@/libs/acl/config'
import router from '@/router'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // burada expire kontrol ettirilecek

          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          const expiresIn = this.getTokenExpiry()

          if (expiresIn / 60 < 20) {
            //   this.refreshToken()
            // router.go()
            if (!this.isAlreadyFetchingAccessToken) {
              this.isAlreadyFetchingAccessToken = true
              this.refreshToken().then(r => {
                this.isAlreadyFetchingAccessToken = false

                // Update accessToken in localStorage
                this.setToken(r.data.accessToken)
                this.setRefreshToken(r.data.refreshToken)

                this.onAccessTokenFetched(r.data.accessToken)
              })
            }
          }
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          this.logoutLocal()

          // this.refreshToken()
          //   router.go()/*
          /*
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          */
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getTokenWİthTokenType() {
    return `${this.jwtConfig.tokenType} ${this.getToken()}`
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  getTokenSetTime() {
    const tokenSetTime = localStorage.getItem(this.jwtConfig.storageTokenSetTimeName)

    return tokenSetTime
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    const setTime = new Date()

    localStorage.setItem(this.jwtConfig.storageTokenSetTimeName, setTime.getTime())
  }

  setTokenExpiry(value) {
    localStorage.setItem(this.jwtConfig.storageTokenExpiryKeyName, value)
  }

  getTokenExpiry() {
    const expiry = localStorage.getItem(this.jwtConfig.storageTokenExpiryKeyName)

    const tokenSetTime = this.getTokenSetTime()

    const now = new Date()

    // eslint-disable-next-line radix
    now.setSeconds(parseInt(now.getSeconds()) + parseInt(expiry))

    const remainingTokenMillis = now.getTime() - tokenSetTime
    return Math.floor(remainingTokenMillis / 1000)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  forgotPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.forgotPasswordEndpoint, ...args)
  }

  resetPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.resetPasswordEndpoint, ...args)
  }

  confirmEmail(...args) {
    return this.axiosIns.post(this.jwtConfig.emailConfirmEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  removeTokens() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  logoutServer() {
    this.axiosIns.post(this.jwtConfig.logoutEndpoint).then(() => {
      this.logoutLocal()
    }).catch(() => {
      this.logoutLocal()
    })
  }

  logoutLocal() {
    // Remove userData from localStorage
    // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageTokenExpiryKeyName)
    localStorage.removeItem(this.jwtConfig.storageTokenSetTimeName)
    // Remove userData from localStorage
    localStorage.removeItem('userData')

    // Reset ability
    // this.$ability.update(initialAbility)

    // Redirect to login page
    router.go()
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    }).catch(() => {
      this.$bvToast.toast(this.$t('Session timeout. Registering to login page...'), {
        title: this.$t('Session timout'),
        variant: 'danger',
        solid: false,
      })
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      router.push({ name: 'auth-login' })
    })
  }
}
