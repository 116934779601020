<template>
  <div>
    <b-alert
      v-if="isSuccess"
      variant="success"
      show
    >
      <h4 class="alert-heading">
        {{ successMsg }}
      </h4>

    </b-alert>
    <b-alert
      v-if="errorData"
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        {{ errorData.message }}
      </h4>
      <div
        v-if="errorData.errors"
        class="alert-body"
      >
        <div
          v-for="(v, k) in errorData.errors"
          :key="k"
        >
          <span
            v-for="error in v"
            :key="error"

            style="display: inline-block;"
          ><strong>*</strong>{{ error }}</span>

        </div>

      </div>
    </b-alert>
  </div>
</template>

<script>

import {
  BAlert,
} from 'bootstrap-vue'

export default {
  name: 'StatusAlert',
  components: {
    BAlert,
  },
  props: {
    isSuccess: {
      type: Boolean,
      default: false,
    },
    errorData: {
      type: Object,
      default: null,
    },
    successMsg: {
      type: String,
      default: 'Transaction Successful',
    },
  },
  data() {
    return {
      collapseID: '',
    }
  },

}
</script>
