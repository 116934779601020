import store from './store'

export default {
  user() {
    return JSON.parse(localStorage.getItem('userData'))
  },
  checkAppUsageStep(stepName) {
    const user = this.user()
    if (user.app_usage_steps) {
      const stepCheck = user.app_usage_steps.filter(a => a.step_name === stepName)
      if (stepCheck.length > 0) {
        return true
      }
    }
    return false
  },
  setUsageStep(stepName) {
    const user = this.user()
    if (user.app_usage_steps) {
      const stepCheck = user.app_usage_steps.filter(a => a.step_name === stepName)
      if (stepCheck.length <= 0) {
        user.app_usage_steps.push({ step_name: stepName })
      }
    } else {
      user.app_usage_steps = [{ step_name: stepName }]
    }
    store.dispatch('app/updateAppUsageSteps', { app_usage_steps: user.app_usage_steps })

    localStorage.setItem('userData', JSON.stringify(user))
  },
}
