export default {
  // Endpoints
  loginEndpoint: '/api/auth/login',
  registerEndpoint: '/api/auth/register',
  refreshEndpoint: '/api/auth/refreshtoken',
  logoutEndpoint: '/api/auth/logout',
  emailConfirmEndpoint: '/api/auth/confirm-email',
  forgotPasswordEndpoint: '/api/auth/forgot-password',
  resetPasswordEndpoint: '/api/auth/reset-password',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageTokenExpiryKeyName: 'tokenExpiry',
  storageTokenSetTimeName: 'tokenSetTime',
}
