/* eslint-disable consistent-return */
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Editor from 'vue-editor-js'
import axios from 'axios'
import i18n from '@/libs/i18n'
import moment from 'moment'
import UniversalSocialauth from 'universal-social-auth'
import router from './router'
import store from './store'
import App from './App.vue'
import staticParams from './static-params'
import helpers from './helpers'
import userHandler from './userHandler'
// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

Vue.use(Editor)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

moment.locale('en')

const oAuthProvides = {
  providers: {
    // apple: {
    //   nonce: '**************',
    //   state: '**************',
    //   clientId: '**************',
    //   redirectUri: 'https://myapp.com/auth/github/callback'
    // },
    //  github: {
    //    clientId: '78bfc07e1f25b5e22c26',
    //    redirectUri: 'https://diadal.com.ng/auth/github/callback',
  //   },
    //  twitch: {
    //    clientId: 'qi4vbk30uuvuqigcd5ioq3egxf67m8',
    //    redirectUri: 'https://diadal.com.ng/auth/twitch/callback',
  //   },
    google: {
      clientId: process.env.VUE_APP_SOCIAL_LOGIN_GOOGLE_CLIENT_ID,
      redirectUri: process.env.VUE_APP_SOCIAL_LOGIN_REDIRECT_URI,
    },
    // facebook: {
    //   clientId: '************',
    //   redirectUri: 'https://myapp.com/auth/facebook/callback'
    // },
    // twitter: {
    //   url: 'https://myapp.com/auth/twitter',
    //   clientId: '********',
    //   redirectUri: 'https://myapp.com/auth/twitter/callback'
    // }
  },
}

Vue.config.productionTip = false

const Oauth = new UniversalSocialauth(axios, oAuthProvides)
Vue.prototype.$Oauth = Oauth

Vue.prototype.$axios = axios
Vue.prototype.$staticParams = staticParams
Vue.prototype.$helpers = helpers
Vue.prototype.$userHandler = userHandler
Vue.prototype.$moment = moment
Vue.prototype.$format_datetime = datetime => moment(datetime).format('LLL')
Vue.prototype.$format_date = date => moment(date).format('LL')
Vue.prototype.$user = () => JSON.parse(localStorage.getItem('userData'))
store.commit('app/UPDATE_USER_DATA', JSON.parse(localStorage.getItem('userData')))
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
